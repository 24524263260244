<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <template v-if="orderList.length == 0">
      <van-empty description="暂无订单" />
    </template>
    <template v-else>
      <template v-for="(item, index) in orderList">
        <div
          :key="index"
          class="box"
          style="margin-top: 10px;"
        >
          <full-order-item
            :key="index"
            :item="item"
          ></full-order-item>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
// 全款订单
import FullOrderItem from './components/fullOrderItem.vue'
export default {
  name: 'FullOrder',
  components: {
    FullOrderItem
  },
  data () {
    return {
      activeTab: 0,
      pageType: 1,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false,
      isLoading: false,
      orderList: []
    }
  },
  computed: {
  },
  created () {
    this.loadList()
  },
  methods: {
    async onRefresh () {
      this.loadList()
      this.isLoading = false
    },
    loadList () {
      this.pageNo = 1
      this.orderList = []
      this.getOrderList()
    },
    async getOrderList () {
      let vm = this
      console.log('进入', this.pageNo)
      if (!this.loading) {
        this.loading = true;
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          // type: this.pageType,
          // orderStatus: this.activeTab
        }
        this.pageNo ++
        try {
          let res = await this.$http.orderApi.fullOrder(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.orderList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.orderList = arr
            this.total = res.msg.total
            console.log(this.orderList.length, res.msg.total)
            this.finished = this.orderList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    // 处理滚动条滚到底部加载数据
    async getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        console.log(456, this.pageNo, this.pageNo * this.pageSize, this.total)
        if ((this.pageNo - 1) * this.pageSize <= this.total) {
          await this.getOrderList()
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  background: #f5f5f5;
  
}
</style>
