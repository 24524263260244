<template>
  <div class="full-box box">
    <!-- 信息 -->
    <div class="top-box box">
      <div class="img-box">
        <van-image :src="item.imgUrls && item.imgUrls[0]"></van-image>
      </div>
      <div class="text-a ellipsis2">
        {{ item.courseName || '--' }}
      </div>

      <div class="addr-box ellipsis">
        <!-- <van-icon
          class="location-icon"
          name="location"
        /> -->
        ￥{{ item.totalAmount || '--' }}
      </div>
    </div>

    <div class="box info-box">
      <!-- 线 -->
      <div class="border-x"></div>
      <div class="item-box item">
        <van-row class="text-box">
          <van-col
            span="8"
            class="left-text"
          >
            订单号：
          </van-col>
          <van-col
            span="16"
            class="right-text"
            style="text-align:right;"
          >
            {{ item.orderNo || '--' }}
          </van-col>
        </van-row>
        <van-row class="text-box">
          <van-col
            span="8"
            class="left-text"
          >
            下单手机号：
          </van-col>
          <van-col
            span="16"
            class="right-text"
            style="text-align:right;"
          >
            {{ item.mobile || '--' }}
          </van-col>
        </van-row>
        <van-row class="text-box">
          <van-col
            span="8"
            class="left-text"
          >
            下单时间：
          </van-col>
          <van-col
            span="16"
            class="right-text"
            style="text-align:right;"
          >
            {{ item.createTime || '--' }}
          </van-col>
        </van-row>
        <van-row class="text-box">
          <van-col
            span="8"
            class="left-text"
          >
            订单状态：
          </van-col>
          <van-col
            span="16"
            class="right-text"
            style="text-align:right;"
          >
            交易完成
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullItem',
  components: {
  },
  props: {
    item: {
      default () {
        return {}
      },
      type: Object,
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.full-box {
  background: #fff;
  padding-bottom: 5px;
  .top-box {
    background: #fff;
    padding: 10px 15px 10px 174px;
    min-height: 98px;
    .img-box {
      width: 144px;
      height: 78px;
      position: absolute;
      left: 15px;
      top: 10px;
      .van-image, img {
        width: 100%;
        height: 100%;
      }
    }
    .text-a {
      line-height: 24px;
      font-size: 14px;
    }
    .price-box {
      margin-top: 16px;
      color: #D5423E;
      font-size: 12px;
    }
  }
  .addr-box {
    font-size: 12px;
    line-height: 14px;
    color: #D5423E;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    left: 175px;
    bottom: 11px;
    .location-icon {
      position: absolute;
      left: 0;
      top: 0;
      line-height:12px;
      font-size: 12px;
      color: #51B464;
    }
  }
  .info-box {
    padding: 0 15px;
  }
  .border-x {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
    margin-bottom: 4px;
  }
  .text-box {
    line-height: 26px;
    color: #646464;
    font-size: 14px;
    .left-text {
      font-size: 14px;
    }
  }
}
</style>
